import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AFKGuidesIntroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page afk-journey'} game="afk">
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_intro.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>Everything you need to know about AFK Journey gacha game.</h2>
          <p>
            Last updated: <strong>22/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="About the game" />
        <p>
          AFK Journey is a{' '}
          <strong>top-down open world auto-chess style gacha game</strong> with
          a combat system revolving around activatable Ultimates and
          automatically activated skills. The game focuses heavily on
          teambuilding, team variety, and utilizing interesting character
          combinations to take on the content ahead of you. The game is
          noteworthy for its colorful cast of characters and unique artstyle,
          something that’s been imported from its sister game:{' '}
          <strong>
            AFK Arena, but this time in a 3D setting instead of 2D sprites.
          </strong>
        </p>
        <h5>Information</h5>
        <p>
          In Esperia, a world that looks like a fantasy picture book, you will
          banish the Miasma with your companions and create your own legends!
          Your companions are already waiting for you at the tavern. Let's go!
        </p>
        <ul>
          <li>
            <strong>Explore Esperia freely this time!</strong> - Embark on your
            own legendary adventure in this magical world with picture book-like
            graphics! Find mysterious treasures and experience the seasons while
            exploring Esperia!
          </li>
          <li>
            <strong>Classic Strategic Cards and Real-Time Battles</strong> -
            Fight battles in real time with classic strategic card gameplay and
            decimate your enemies with your ultimate!
          </li>
          <li>
            <strong>
              Control enemies with different types of terrain for greater fun in
              battles
            </strong>{' '}
            - The situation keeps changing in battle! Chosen One, you'll need to
            adjust your formation according to the terrain and use the combat
            mechanisms wisely to defeat powerful enemies!
          </li>
          <li>
            <strong>
              Get yourself prepared and take a proper break when necessary
            </strong>{' '}
            - When you're tired, take a break and relax around a campfire.
            However, the adventure continues even when you're resting. Wake up
            to collect lots of loot!
          </li>
        </ul>
        <p>To learn more about the game, check our beginner guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Beginner guide"
            link="/afk-journey/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_beginner.jpg"
                alt="Introduction to the game"
              />
            }
          />
        </Row>
        <SectionHeader title="Official links" />
        <ul>
          <li>
            Official Website:{' '}
            <a
              href="https://afkjourney.farlightgames.com/#/index"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Discord:{' '}
            <a
              href="https://discord.gg/afkjourney"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official YouTube:{' '}
            <a
              href="https://www.youtube.com/@AFKJourneyOfficial"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Google Play Store:{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.farlightgames.igame.gp&hl=en&gl=GB"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Apple Store:{' '}
            <a
              href="https://apps.apple.com/us/app/afk-journey/id1628970855"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
        </ul>
        <SectionHeader title="Official images" />
        <Row xs={1} xl={2}>
          <Col>
            <StaticImage src="../../../images/afk/promo.jpg" alt="Guides" />
          </Col>
          <Col>
            <StaticImage src="../../../images/afk/promo2.jpg" alt="Guides" />
          </Col>
          <Col>
            <StaticImage src="../../../images/afk/promo3.jpg" alt="Guides" />
          </Col>
          <Col>
            <StaticImage src="../../../images/afk/promo4.jpg" alt="Guides" />
          </Col>
          <Col>
            <StaticImage src="../../../images/afk/promo5.jpg" alt="Guides" />
          </Col>
        </Row>
        <SectionHeader title="Video" />
        <p>For more information check this great video from Volkin:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="w54GMhzt60o" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | AFK Journey | Prydwen Institute"
    description="Everything you need to know about AFK Journey gacha game."
    game="afk"
  />
);
